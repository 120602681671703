body {
    margin: 0;
    padding: 0;
    font-family: IBM Plex Sans;
  }
  .App {
    position: relative;
  }
  
  .App-logo {
    height: 80px;
  }
  
 
  section {
    padding: 50px 0;
    max-width: 600px;
    margin: 0 auto;
  }
  .page {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
  }
  
  /* simple - enter transition 300ms, exit 150ms */
  .fade-appear,
  .fade-enter {
      opacity: 0;
      z-index: 1;
  }
  .fade-appear-active,
  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 300ms linear 150ms;
  }
  
  .fade-exit {
      opacity: 1;
  }
  
  .fade-exit.fade-exit-active {
      opacity: 0;
      transition: opacity 150ms linear;
  }
  